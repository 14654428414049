import {Event} from "./events";
import {Logger} from "../lib/logger";
import {background, type Context, ContextKey} from "../lib/context";
import {uuidv7} from "../lib/uuid";

export type EventHandler = (ctx: Context, event: MessageEvent) => Promise<void>;
export class EventManager {
    private handlers: Map<Event, EventHandler>;
    private logger: Logger;
    private readonly defaultOrigin: string = "embedded";
    private eventQueue: MessageEvent[] = [];
    private isProcessing = false;
    private isConfiguringInProgress = false;

    constructor(debug: boolean) {
        this.logger = new Logger(debug);
        this.handlers = new Map<Event, EventHandler>();
    }

    on(event: Event, handler: EventHandler): void {
        this.handlers.set(event, handler);
    }

    handleEvent(event: MessageEvent): void {
        if (!event.data.action) {
            return;
        }

        this.eventQueue.push(event);
        if (!this.isProcessing) {
            this.processNextEvent();
        }
    }

    private async processNextEvent(): Promise<void> {
        this.isProcessing = true;
        if (this.eventQueue.length === 0) {
            this.isProcessing = false;
            return;
        }

        const event = this.eventQueue.shift();
        if (!event) {
            this.isProcessing = false;
            return;
        }

        let origin = "";
        try {
            origin = new URL(event.origin).host;
        } catch (e) { /* empty */ }

        if (origin === "") {
            origin = this.defaultOrigin;
        }

        let ctx = background().withValue(ContextKey.ORIGIN, origin);
        ctx = ctx.withValue(ContextKey.REQUEST, uuidv7());
        this.logger.info(ctx, "Received event", event.data);
        const handler: EventHandler | undefined = this.handlers.get(event.data.action);

        if (handler) {
            if (event.data.action === Event.CONFIGURE) {
                if (this.isConfiguringInProgress) {
                    this.logger.info(ctx, "Configure event received while another is in progress. Queueing.");
                    this.eventQueue.unshift(event);
                    this.isProcessing = false;
                    return;
                }
                this.isConfiguringInProgress = true;
            }

            try {
                await handler(ctx, event);
            } catch (error) {
                this.logger.error(ctx, "Error processing event", {error: error as Record<string, unknown>});
            } finally {
                if (event.data.action === Event.CONFIGURE) {
                    this.isConfiguringInProgress = false;
                }
            }
        }

        this.processNextEvent();
    }

    listen(): void {
        window.addEventListener("message", this.handleEvent.bind(this));
        window.parent.postMessage({uuid: "FIRST", action: Event.LOADED}, "*");
    }

    close(): void {
        window.removeEventListener("message", this.handleEvent.bind(this));
    }
}